import { Button } from "components/atoms";
import growingLogo from "../../assets/images/growing_symbol_sm.png";
import usePageType from "hooks/usePageType";

import * as S from "./MainPage.styled";

import { useNavigate } from "react-router-dom";
import BasicLayout from "components/templates/basic-layout";

const MainPage = () => {
  const navigate = useNavigate();

  usePageType("normal");

  return (
    <BasicLayout>
      <S.Container>
        <S.ContentContainer>
          <S.TitleContainer>
            <S.Logo src={growingLogo} alt="main" />
            <S.Title>그로잉어학원</S.Title>
          </S.TitleContainer>
          <S.SubTitleContainer>
            <S.SubTitle>{`영어로 생각하는 그릇을 만듭니다.\nThink in English`}</S.SubTitle>
          </S.SubTitleContainer>
          <S.ButtonContainer>
            <Button variant="secondary" onClick={() => navigate("/learningcenter")}>
              학생용 페이지
            </Button>
            <Button onClick={() => navigate("/teachingcenter")}>교사용 페이지</Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default MainPage;
