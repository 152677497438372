// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRi4c65lKfjauOsfnt2mR2E3tNENe2tsg",
  authDomain: "growingenglish.co.kr",
  projectId: "growingenglish-fd3a1",
  storageBucket: "growingenglish-fd3a1.appspot.com",
  messagingSenderId: "494153733627",
  appId: "1:494153733627:web:f53328df9d50eb1b56e75e",
  measurementId: "G-MKQE1C58RH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
});

export const storage = getStorage();

export default db;
