import { call, put, takeEvery } from "redux-saga/effects";
import { getTodoListBySidAndDeadlineAPI } from "../../../api/todoApi";

const GET_TODO_BY_SID_AND_DEADLINE = "GET_TODO_BY_SID_AND_DEADLINE";
const GET_TODO_BY_SID_AND_DEADLINE_SUCCESS = "GET_TODO_BY_SID_AND_DEADLINE_SUCCESS";
const GET_TODO_BY_SID_AND_DEADLINE_ERROR = "GET_TODO_BY_SID_AND_DEADLINE_ERROR";

export const getTodoListBySidAndDeadline = (sid, deadline) => ({
  type: GET_TODO_BY_SID_AND_DEADLINE,
  sid,
  deadline,
});

function* getTodoListBySidAndDateSaga(action) {
  const { sid, deadline } = action;
  try {
    const payload = yield call(getTodoListBySidAndDeadlineAPI, sid, deadline);
    console.log(payload);
    yield put({
      type: GET_TODO_BY_SID_AND_DEADLINE_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: GET_TODO_BY_SID_AND_DEADLINE_ERROR,
      error: true,
      e,
    });
  }
}

export function* teacherTodoSaga() {
  yield takeEvery(GET_TODO_BY_SID_AND_DEADLINE, getTodoListBySidAndDateSaga);
}

const teacherTodoReducer = (state = [], action) => {
  switch (action.type) {
    case GET_TODO_BY_SID_AND_DEADLINE:
      return state;
    case GET_TODO_BY_SID_AND_DEADLINE_SUCCESS:
      return action.payload;
    case GET_TODO_BY_SID_AND_DEADLINE_ERROR:
      return state;
    default:
      return state;
  }
};

export default teacherTodoReducer;
