import "../../../styles/ManageTable.scss";
import { Tabs, Tab } from "react-bootstrap";
import UserManageTable from "../../../Component/Admin/UserManage/UserManageTable";
import useRedirect from "../../../hooks/useRedirect";
import UploadData from "uploadData";

import BasicLayout from "components/templates/basic-layout";
import * as S from "components/templates/common-container/CommonContainer.styled";

// 사용자 관리를 위한 컨테이너
const ManageUser = () => {
  useRedirect("teachingcenter", "home");

  return (
    <BasicLayout headerType="teacher">
      <S.Container>
        <S.ContentContainer>
          <Tabs defaultActiveKey="student" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="student" title="학생">
              <UserManageTable />
            </Tab>
            <Tab eventKey="teacher" title="선생님">
              <UserManageTable isTeacher />
            </Tab>
          </Tabs>
          <UploadData />
        </S.ContentContainer>
      </S.Container>
    </BasicLayout>
  );
};

export default ManageUser;
