import { css } from "@emotion/react";

interface TypographyParams {
  fontFamily: string;
  fontSize: number;
  lineHeight: number;
}

const generateFont = ({ fontFamily, fontSize, lineHeight }: TypographyParams) => {
  return css`
    font-family: ${fontFamily};
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
  `;
};

export const HeadLine = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 50, lineHeight: 60 });
export const H1 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 34, lineHeight: 40 });
export const H2 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 28, lineHeight: 34 });
export const H3 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 22, lineHeight: 28 });
export const Title1 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 20, lineHeight: 25 });
export const Title2 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 17, lineHeight: 22 });
export const Body1 = generateFont({ fontFamily: "Pretendard-Regular", fontSize: 17, lineHeight: 22 });
export const Body2 = generateFont({ fontFamily: "Pretendard-Regular", fontSize: 16, lineHeight: 21 });
export const Body3 = generateFont({ fontFamily: "Pretendard-Regular", fontSize: 15, lineHeight: 20 });
export const Button1 = generateFont({ fontFamily: "Pretendard-Bold", fontSize: 16, lineHeight: 20 });
export const Caption1 = generateFont({ fontFamily: "Pretendard-Regular", fontSize: 13, lineHeight: 18 });
